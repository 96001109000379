<template>
  <div class="loginBigBox">
    <div class="content">
      <div class="title"><img src="../assets/images/logo.png" alt="" /></div>
      <div class="contentBox" v-if="!forgetPwd">
        <div class="loginStyle">
          <div
            @click="loginStyle = 0"
            :class="['pwdStyle', loginStyle == 0 ? 'isStyleActive' : '']"
          >
            密码登录
          </div>
          <div
            @click="loginStyle = 1"
            :class="['msgStyle', loginStyle == 1 ? 'isStyleActive' : '']"
          >
            短信登录
          </div>
        </div>
        <div class="pwdLogin" v-show="loginStyle == 0">
          <el-input :spellcheck="false" v-model="account" placeholder="请输入账号" clearable>
            <template #prefix> <i class="iconfont icon-zhanghao"></i> </template
          ></el-input>
          <el-input :spellcheck="false" placeholder="请输入密码" v-model="pwd" show-password>
            <template #prefix>
              <i class="iconfont icon-mima"></i>
            </template>
          </el-input>
        </div>
        <div class="yzmLogin" v-show="loginStyle == 1">
          <el-input
            ref="telInput"
            @input="inputTel"
            @blur="checkTel"
            :spellcheck="false"
            v-model="cloneTel"
            placeholder="请输入手机号"
            clearable
          >
            <template #prefix> <i class="iconfont icon-icon-technology-fi-phone"></i> </template
          ></el-input>
          <el-input
            maxlength="5"
            @input="checkYzm"
            :spellcheck="false"
            v-model="cloneYzm"
            placeholder="请输入验证码"
          >
            <template #prefix> <i class="iconfont icon-yanzhengma"></i> </template>
            <template #suffix>
              <div v-loading v-if="!yzmFlag" class="getYzm" @click="getYZM">获取验证码</div>
              <div class="second" v-else>{{ yzmsecond }}s后重新发送</div>
            </template>
          </el-input>
        </div>
        <div class="pwdMenu">
          <div v-show="loginStyle == 0">
            <el-checkbox v-model="isRemeberPwd">记住密码</el-checkbox>
          </div>
          <div
            @click="forgetPwd = true"
            style="cursor: pointer; user-select: none"
            v-show="loginStyle == 0"
          >
            忘记密码
          </div>
        </div>
        <div
          :style="{ marginTop: loginStyle == 1 ? '20px' : '' }"
          class="loginBtn custBtnPri"
          @click="login"
        >
          登录
        </div>
      </div>
      <div class="contentBox Box2" v-else>
        <div class="topTitle">找回密码</div>
        <div class="desc">可通过手机号验证设置新密码</div>
        <el-input
          ref="telInput"
          @input="inputresetTel"
          @blur="checkresetTel"
          :spellcheck="false"
          v-model="cloneResetTel"
          placeholder="请输入手机号"
          clearable
        >
          <template #prefix> <i class="iconfont icon-icon-technology-fi-phone"></i> </template
        ></el-input>
        <el-input
          maxlength="5"
          @input="checkResetYzm"
          :spellcheck="false"
          v-model="cloneResetYzm"
          placeholder="请输入验证码"
        >
          <template #prefix> <i class="iconfont icon-yanzhengma"></i> </template>
          <template #suffix>
            <div v-loading v-if="!resetYzmFlag" class="getYzm" @click="resetgetYZM">获取验证码</div>
            <div class="second" v-else>{{ resetSecond }}s后重新发送</div>
          </template>
        </el-input>
        <el-input placeholder="请输入新密码" v-model="newPwd" show-password>
          <template #prefix>
            <i class="iconfont icon-mima"></i>
          </template>
        </el-input>
        <div class="sureUpdate" @click="sureUpdate">确认修改</div>
        <div @click="forgetPwd = false" class="goLogin">去登录</div>
      </div>
    </div>
  </div>
  <div class="fixBot">©2015-2021 power by Egg Digital</div>
  <div class="modal" v-if="showImgYzm">
    <div class="top">
      <span>请完成验证</span>
      <i @click="showImgYzm = false" class="iconfont icon-guanbi1"></i>
    </div>
    <SliderVerification @success="success"></SliderVerification>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { post } from '@/util/axios'
import { api } from '@/util/api'
import { ElMessage } from 'element-plus'
import { inspectPhone } from '@/util/common'
import SliderVerification from '@/components/yzImg'
export default {
  components: {
    SliderVerification
  },
  setup() {
    const $router = useRouter()
    const $route = useRoute()
    const pwdLogin = reactive({
      account: '',
      pwd: ''
    })
    // 记录是获取哪个验证码 login->登录 reset ->忘记密码
    let whichYzm = 'login'
    const telInput = ref()
    let loginStyle = ref(0)
    let isRemeberPwd = ref(false)
    let forgetPwd = ref(false)
    let rName = window.btoa('isRem')
    let cName = window.btoa('account')
    let pName = window.btoa('password')
    if ($route.query.account) {
      pwdLogin.account = $route.query.account
    } else {
      if (localStorage.getItem(rName)) {
        isRemeberPwd.value = true
        pwdLogin.account = window.atob(localStorage.getItem(cName))
        pwdLogin.pwd = window.atob(localStorage.getItem(pName))
      }
    }
    // 登录函数
    const login = () => {
      if (loginStyle.value === 0) {
        if (pwdLogin.account == '' || pwdLogin.pwd == '') {
          ElMessage.warning('请填写完整账号密码')
          return
        }
      } else {
        if (yzmLogin.tel == '' || yzmLogin.yzm == '') {
          ElMessage.warning('请填写完整账号验证码')
          return
        }
      }
      let obj = {}
      if (loginStyle.value === 0) {
        obj = {
          mobile: pwdLogin.account,
          password: pwdLogin.pwd
        }
      } else {
        obj = {
          mobile: yzmLogin.tel,
          code: yzmLogin.yzm
        }
      }
      post(loginStyle.value === 0 ? api.login : api.codeLogin, obj).then((res) => {
        if (res.code == 0) {
          ElMessage.success('登录成功')
          sessionStorage.setItem('info', JSON.stringify(res.result))
          sessionStorage.setItem('token', res.result.token)
          if (loginStyle.value === 0 && isRemeberPwd.value) {
            // 保存密码
            let c = window.btoa(pwdLogin.account)
            let p = window.btoa(pwdLogin.pwd)
            localStorage.setItem(rName, 1)
            localStorage.setItem(cName, c)
            localStorage.setItem(pName, p)
          }
          $router.push('/home')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    // 检测验证码是否输入的数字
    const checkYzm = (val) => {
      const reg = /^[0-9]*$/
      if (!reg.test(val)) {
        yzmLogin.cloneYzm = yzmLogin.yzm
      } else {
        yzmLogin.yzm = val
      }
    }
    // 正则验证手机号是否合法
    const checkTel = () => {
      yzmLogin.telOK = inspectPhone(yzmLogin.tel)
    }
    // 检测手机号是否输入的数字
    const inputTel = (val) => {
      const reg = /^[0-9]*$/
      if (!reg.test(val)) {
        yzmLogin.cloneTel = yzmLogin.tel
      } else {
        yzmLogin.tel = val
      }
    }
    // 正则验证手机号是否合法
    const checkresetTel = () => {
      resetPwd.telOK = inspectPhone(resetPwd.resetTel)
    }
    // 检测手机号是否输入的数字
    const inputresetTel = (val) => {
      const reg = /^[0-9]*$/
      if (!reg.test(val)) {
        resetPwd.cloneResetTel = resetPwd.resetTel
      } else {
        resetPwd.resetTel = val
      }
    }
    const yzmLogin = reactive({
      tel: '',
      cloneTel: '',
      telOK: false,
      yzm: '',
      cloneYzm: '',
      yzmFlag: false,
      yzmsecond: 60
    })
    // 获取验证码
    const getYZM = () => {
      if (yzmLogin.tel == '') {
        ElMessage.warning(`请输入手机号`)
        return
      }
      if (!yzmLogin.telOK) {
        ElMessage.warning('请输入正确的手机号')
        return
      }
      whichYzm = 'login'
      useYzm(true)
    }
    const checkResetYzm = (val) => {
      const reg = /^[0-9]*$/
      if (!reg.test(val)) {
        resetPwd.cloneResetYzm = resetPwd.resetYzm
      } else {
        resetPwd.resetYzm = val
      }
    }
    // 找回密码数据
    const resetPwd = reactive({
      resetTel: '',
      telOK: false,
      cloneResetTel: '',
      resetYzm: '',
      cloneResetYzm: '',
      resetYzmFlag: false,
      resetSecond: 60,
      newPwd: ''
    })
    // 忘记密码获取验证码
    const resetgetYZM = () => {
      if (resetPwd.resetTel == '') {
        ElMessage.warning(`请输入手机号`)
        return
      }
      if (!resetPwd.telOK) {
        ElMessage.warning('请输入正确的手机号')
        return
      }
      whichYzm = 'reset'
      useYzm(true)
    }
    // 确认修改信息
    const sureUpdate = () => {
      if (resetPwd.resetTel == '' || resetPwd.resetYzm == '' || resetPwd.newPwd == '') {
        ElMessage.warning('请信息填写完整')
        return
      }
      if (!resetPwd.telOK) {
        ElMessage.warning('请填写合法手机号')
        return
      }
      post(api.forgetPwd, {
        mobile: resetPwd.resetTel,
        password: resetPwd.newPwd,
        code: resetPwd.resetYzm
      }).then((res) => {
        if (res.code === 0) {
          pwdLogin.account = resetPwd.resetTel
          pwdLogin.pwd = resetPwd.newPwd
          forgetPwd.value = false
          loginStyle.value = 0
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    // 图片验证码
    const showImgYzm = ref(false)
    // 滑块验证成功
    const success = () => {
      if (whichYzm === 'login') {
        post(api.systemSendSms, {
          mobile: yzmLogin.tel
        }).then((res) => {
          if (res.code == 0) {
            yzmLogin.yzmFlag = true
            let time = setInterval(() => {
              yzmLogin.yzmsecond--
              if (yzmLogin.yzmsecond === 0) {
                clearInterval(time)
                yzmLogin.yzmsecond = 60
                yzmLogin.yzmFlag = false
              }
            }, 1000)
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else if (whichYzm === 'reset') {
        post(api.systemSendSms, {
          mobile: resetPwd.resetTel
        }).then((res) => {
          if (res.code == 0) {
            resetPwd.resetYzmFlag = true
            let time1 = setInterval(() => {
              resetPwd.resetSecond--
              if (resetPwd.resetSecond === 0) {
                clearInterval(time1)
                resetPwd.resetYzmFlag = false
                resetPwd.resetSecond = 60
              }
            }, 1000)
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
      useYzm(false)
    }
    // 是否调用滑块验证
    const useYzm = (flag) => {
      showImgYzm.value = flag
    }
    return {
      ...toRefs(pwdLogin),
      ...toRefs(yzmLogin),
      ...toRefs(resetPwd),
      login,
      checkYzm,
      checkTel,
      inputTel,
      getYZM,
      inputresetTel,
      checkresetTel,
      checkResetYzm,
      resetgetYZM,
      sureUpdate,
      telInput,
      loginStyle,
      isRemeberPwd,
      forgetPwd,
      success,
      showImgYzm
    }
  }
}
</script>

<style lang="less" scoped>
.loginBigBox {
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/loginBg.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .contentBox {
      margin-top: 55px;
      background-color: #fff;
      padding: 20px 40px;
      border-radius: 4px;
      .loginStyle {
        width: 100%;
        display: flex;
        color: #9ea4b4;
        font-size: 16px;
        border-bottom: 2px solid #f1f2f7;
        & > div {
          flex: 1;
          text-align: center;
          padding: 10px 0;
          cursor: pointer;
        }
        .isStyleActive {
          color: #333333;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: #1467ff;
            position: absolute;
            left: 0;
            bottom: -2px;
          }
        }
      }
      .pwdLogin {
        display: flex;
        flex-direction: column;
      }
      .yzmLogin {
        display: flex;
        flex-direction: column;
        .getYzm {
          cursor: pointer;
          color: #1467ff;
          user-select: none;
          margin-right: 30px;
        }
        .second {
          user-select: none;
          margin-right: 30px;
        }
      }
      .pwdMenu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 18px 0 30px 0;
        height: 17px;
      }
      .loginBtn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-radius: 25px;
      }
    }
  }
}
.el-input {
  width: 300px;
  margin-top: 20px;
  :deep(.el-input__inner) {
    border-radius: 40px;
    padding-left: 48px;
  }
  :deep(.el-input__prefix) {
    left: 24px;
  }
}
.fixBot {
  position: fixed;
  left: 50%;
  bottom: 20px;
  color: #999999;
  font-size: 12px;
  transform: translateX(-50%);
}
.Box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  .topTitle {
    font-size: 18px;
    color: #333333;
  }
  .desc {
    font-size: 12px;
    color: #999999;
    margin-top: 7px;
  }
  .getYzm {
    cursor: pointer;
    color: #1467ff;
    user-select: none;
    margin-right: 30px;
  }
  .sureUpdate {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 20px 0;
    background-color: #1467ff;
    cursor: pointer;
    user-select: none;
    border-radius: 20px;
    color: #fff;
  }
  .goLogin {
    align-self: center;
    user-select: none;
    cursor: pointer;
    color: #1467ff;
    font-weight: bold;
  }
}
.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .top {
    width: 310px;
    padding: 20px 15px;
    background-color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      cursor: pointer;
    }
  }
}
</style>
